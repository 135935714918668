<template>
	<div class="home">
		<table-transaction></table-transaction>
	</div>
</template>

<script>
import tableTransaction from '@/components/pages/maint/transaction/tableTransaction.vue';

export default {
	components: {
		'table-transaction': tableTransaction,
	}
};
</script>

<style>

</style>

