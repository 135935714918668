<template>
     <v-dialog v-model="dialog" persistent max-width="70%">
     <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'transaction'"></app-add-button>
        </template>
        <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
          <v-card title>
           <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn 
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>

        <v-card-text>
            <v-container grid-list-md>
            <v-layout wrap>
                <v-flex xs12 sm6 md4>                    
                  <v-text-field 
                     v-model="editedItem.trns_code"
                    :counter="2"
                    :rules="trns_codeRules"
                    label="Transaction Code"
                    required
                    maxlength=2
                ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                    v-model="editedItem.document_type"
                   :items="documentType"
                   :rules="[v => !!v || 'Please select Document Type']"
                   label="Document Type"
                    data-vv-name="documentType"
                    required
                ></v-select>
                </v-flex>
                <v-flex xs12 sm6 md4>
                 <v-text-field 
                    v-model="editedItem.trns_desc"
                    :counter="250"
                    :rules="trns_descRules"
                    label="Transaction Desc"
                    required
                    maxlength=250
                  ></v-text-field>
                </v-flex>
            </v-layout>
            </v-container>
        </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		trns_codeRules: [
			v => !!v || 'Transaction Code is required.',
			//  v => (v && v.length <=2) || 'Transaction Code must be less or equal to 2 numbers',
		],
		document_typeRules: [
			v => !!v || 'Document Type is required.',
			v => (v && v.length <=3) || 'Document Type must be less or equal to 3 numbers',
		],
		trns_descRules: [
			v => !!v || 'Transaction Decription is required',
			v => (v && v.length <= 250) || 'Transaction Decription must be less than 250 characters',
		],
      
	}),

	computed: {
		...mapGetters({
			transactions: 'transaction/transactions',
			trnsCode: 'transaction/trnsCode',
			documentType: 'transaction/documentType',
			trnsDesc: 'transaction/trnsDesc',
			editedItem: 'transaction/editedItem',
			defaultItem: 'transaction/defaultItem',
			dialog: 'transaction/dialog',
			editedIndex: 'transaction/editedIndex',
			formTitle: 'transaction/formTitle',
			valid: 'transaction/valid',
			isLoading: 'app/isLoading'

		}),
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('transaction/setValid',true);
				this.$store.dispatch('transaction/setDialog',false);
				this.$store.dispatch('transaction/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('transaction/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('transaction/updateTransaction',this.editedItem);
				} else {
					this.$store.dispatch('transaction/saveTransaction',this.editedItem);
				}
				this.close();
			}
		},
      
	}
};
</script>
