<template>
  <div>
    
  <v-data-table
    :headers="headers"
    :items="transactions"
    sort-by="document_type"
    class="elevation-1"
    dense
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Disbursement Transaction File Maintenance</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
       color="primary"
        class="mr-2"
        small
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
        small
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
  </v-data-table>
  <create-transaction></create-transaction>
  </div>
</template>

<script>
import createTransaction from './createTransaction.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-transaction': createTransaction
	},
	data(){
		return{
			search: ''
		};
	},
	computed: {
		...mapGetters({
			transactions: 'transaction/transactions',
			headers: 'transaction/headers',
			editedItem: 'transaction/editedItem',
			defaultItem: 'transaction/defaultItem',
			dialog: 'transaction/dialog',
			editedIndex: 'transaction/editedIndex',
			valid: 'transaction/valid'
		}),
	},
	mounted () {
		this.$store.dispatch('transaction/getTransactions');
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('transaction/setEditedIndex',this.transactions.indexOf(item));
			this.$store.dispatch('transaction/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('transaction/setDialog',true);
			this.$store.dispatch('transaction/setValid',true);
			this.$store.dispatch('transaction/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.trns_code + '(' + item.document_type + ')' + '(' + item.trns_desc + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('transaction/setisLoading',true);
					this.$store.dispatch('transaction/deleteTransaction',item);
				}
			});
		}
	}
};
</script>